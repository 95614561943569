// グループの情報を更新

import api from '../api'

const path = '/admin/user-groups/{id}'

export default (userGroupId, name) => {
  const url = path.replace('{id}', userGroupId)
  return api({
    method: 'put',
    url,
    params: {
      name,
    },
    auth: true,
  })
}
