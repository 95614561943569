import api from '../api'

// ユーザの詳細情報の取得

const path = '/admin/users/{id}'

export default (userId) => {
  const url = path.replace('{id}', userId)
  return api({
    url,
    auth: true,
  })
}
