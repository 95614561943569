// グループ管理

<template lang="pug">
el-card.admin-group

  .go-back(style='margin-bottom: 0.5rem')
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  header
    el-button(
      type='primary',
      icon='el-icon-circle-plus',
      @click='addGroup'
    ) グループを追加
    el-button(
      type='success',
      icon='el-icon-delete',
      :disabled='!groupSelected'
      plain,
      @click='modifyGroup'
    ) グループを編集
    el-button(
      type='danger',
      icon='el-icon-delete',
      :disabled='!groupSelected'
      plain,
      @click='removeGroup'
    ) グループを削除
    a.el-button.el-button--warning.is-plain(
      :href='applicationFormUrl',
      :class='{ "is-disabled": !applicationFormUrl }',
      :disabled='!applicationFormUrl',
      target='_blank',
      style='text-decoration: none;',
      rel='noopener'
    ) グループの登録用紙印刷

  article

    el-tabs(type='card', v-model='currentTab')
      el-tab-pane(
        v-for='item in groups',
        :label='item.name',
        :key='item.id',
        :name='"" + item.id'
      )

    .members(v-if='groupSelected && members')

      .note
        | ※ 登録が「途中」となっているメンバーは、メール送信済みで、メール記載のリンクを開いていない状態を表しています。

      .header
        h3 メンバーの一覧 (登録済: {{ memberNum }}名 / 登録途中: {{ notActivatedMemberNum }}名)
        el-button.add(
          type='text',
          icon='el-icon-circle-plus',
          @click='addGroupMember'
        ) メンバーの手動追加
        csv-download-link.download-link(
          filename='メンバー一覧.csv',
          :data-array-array='dataForCsv'
        ) Excel用ファイル(CSV)のダウンロード

      el-table(
        :data='members'
        :row-class-name='tableRowClassName'
      )

        el-table-column(
          label='登録',
          width='60',
          align='center'
        )
          template(slot-scope='scope')
            span.status {{ scope.row.activatedAt ? '済' : '途中' }}

        el-table-column(
          prop='loginName',
          label='受講者番号',
          width='100'
        )

        el-table-column(
          prop='organizationRegion',
          label='地区',
          width='100'
        )

        el-table-column(
          prop='organizationName',
          label='園名'
        )

        el-table-column(
          label='名前'
        )
          template(slot-scope='scope')
            span {{ scope.row.firstName }} {{ scope.row.lastName }}
            br
            //- リンクを開くとアクティベートされてしまうので、
            //- アクティベート済みしかマイページへのリンクは表示しない
            a(
              v-if='scope.row.activatedAt',
              :href='`/s/${scope.row.accessKey}`',
              target='_blank',
              rel='noopener'
            ) マイページ

        el-table-column(
          label='ふりがな'
        )
          template(slot-scope='scope')
            span {{ scope.row.firstNameKana }} {{ scope.row.lastNameKana }}

        el-table-column(
          prop='email'
          label='メール'
        )

        el-table-column
          template(slot-scope='scope')
            el-button(
              size='mini',
              type='success',
              plain,
              @click='modifyGroupMember(scope.row.userId)'
            ) 編集
            el-button(
              size='mini',
              type='danger',
              plain,
              @click='removeGroupMember(scope.row.userId)'
            ) 削除

  modify-group-member-dialog(
    :visible.sync='dialogVisible',
    :user-group-id='currentGroup ? currentGroup.id : null',
    :group-member-id='currentGroupMemberId',
    @ok='updateGroupMember'
  )
</template>

<script>
import getGroupsApi from '@/api/admin/get-group-list'
import getGroupMembersApi from '@/api/admin/get-group-members'
import createGroupApi from '@/api/admin/create-group'
import removeGroupApi from '@/api/admin/remove-group'
import updateGroupApi from '@/api/admin/update-group'
import removeUserApi from '@/api/admin/remove-user'

import ModifyGroupMemberDialog from '@/dialogs/modify-group-member-dialog'

import CsvDownloadLink from '@/components/csv-download-link'

// CSV出力する際の要素
const csvHeads = [
  { name: '登録', transform: (item) => (item.activatedAt ? '済' : '途中') },
  { name: '受講者番号', key: 'loginName' },
  { name: '地区', key: 'organizationRegion' },
  { name: '園名', key: 'organizationName' },
  { name: '名前', transform: (item) => `${item.firstName} ${item.lastName}` },
  { name: 'ふりがな', transform: (item) => `${item.firstNameKana} ${item.lastNameKana}` },
  { name: 'メール', key: 'email' },
]

export default {
  name: 'AdminGroup',

  components: {
    ModifyGroupMemberDialog,
    CsvDownloadLink,
  },

  data() {
    return {
      groups: [],
      members: [],
      currentTab: '0',
      dialogVisible: false,
      currentGroupMemberId: null,
    }
  },

  computed: {
    // アクテベーション済みメンバーの数
    memberNum() {
      return this.members.filter((item) => item.activatedAt).length
    },
    // アクティベーションまだのメンバー数
    notActivatedMemberNum() {
      return this.members.filter((item) => !item.activatedAt).length
    },
    // グループが選択されているか
    groupSelected() {
      return this.currentTab !== '0'
    },
    // 選択中のグループ名
    currentGroup() {
      if (!this.groupSelected) return null
      const id = parseInt(this.currentTab, 10)
      return this.groups.find((item) => item.id === id)
    },
    // 応募用紙のURL
    applicationFormUrl() {
      if (!this.currentGroup) return null
      const url = this.$router.resolve({
        name: 'AdminGroupApplicationForm',
      }).href
      console.log(url)
      return `${url}?name=${this.currentGroup.name}&code=${this.currentGroup.code}`
    },
    // CSV用のデータ
    dataForCsv() {
      const arrayArray = []
      arrayArray.push(csvHeads.map((head) => head.name))
      this.members.forEach((item) => {
        arrayArray.push(
          csvHeads.map((head) => {
            if (head.transform) return head.transform(item)
            else return item[head.key]
          })
        )
      })
      return arrayArray
    },
  },

  async created() {
    await this.init(true)
  },

  methods: {
    async init(initCurrentTab = false) {
      // 受講者のみ取得
      const response = await getGroupsApi('student')
      if (!response.ok) return
      this.groups = response.payload.items
      if (initCurrentTab) {
        if (response.payload.items.length > 0) {
          this.currentTab = '' + response.payload.items[0].id
        } else {
          this.currentTab = '0'
        }
      }
    },

    async updateMembers() {
      if (!this.groupSelected) return
      const response = await getGroupMembersApi(this.currentTab, true)
      if (!response.ok) return
      this.members = response.payload.items
    },

    // グループの追加
    async addGroup() {
      let groupName
      try {
        const { value } = await this.$prompt(
          '作成するグループ名を入力してください。',
          'グループの作成',
          {
            confirmButtonText: '作成',
            cancelButtonText: 'キャンセル',
            inputPattern: /.{1,}/,
            inputErrorMessage: 'グループ名を入力してください。',
          }
        )
        groupName = value
      } catch (e) {
        return
      }
      // グループ追加
      const response = await createGroupApi(groupName)
      if (!response.ok) return
      await this.init()
    },

    async modifyGroup() {
      let groupName
      try {
        const { value } = await this.$prompt(
          '新しいグループ名を入力してください。',
          'グループの編集',
          {
            confirmButtonText: '更新',
            cancelButtonText: 'キャンセル',
            inputValue: this.currentGroup.name,
            inputPattern: /.{1,}/,
            inputErrorMessage: 'グループ名を入力してください。',
          }
        )
        groupName = value
      } catch (e) {
        return
      }
      // グループの更新
      const response = await updateGroupApi(this.currentTab, groupName)
      if (!response.ok) return
      await this.init()
    },

    // グループの削除
    async removeGroup() {
      try {
        await this.$confirm('本当に削除してもよろしいですか。', '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
          type: 'warning',
        })
      } catch (e) {
        return
      }
      const response = await removeGroupApi(this.currentTab)
      if (!response.ok) {
        this.$alert('削除できませんでした。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: '削除しました。',
          type: 'success',
        })
        this.init(true)
      }
    },

    tableRowClassName({ row }) {
      return row.activatedAt ? 'is-activated' : 'is-not-activated'
    },

    // メンバの新規追加
    addGroupMember() {
      this.currentGroupMemberId = null
      this.dialogVisible = true
    },

    // メンバの編集
    modifyGroupMember(groupMemberId) {
      this.currentGroupMemberId = groupMemberId
      this.dialogVisible = true
    },

    // メンバの削除
    async removeGroupMember(groupMemberId) {
      try {
        await this.$confirm('本当に削除してもよろしいですか。', '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
          type: 'warning',
        })
      } catch (e) {
        return
      }
      const response = await removeUserApi(groupMemberId)
      if (!response.ok) {
        this.$alert('削除できませんでした。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: '削除しました。',
          type: 'success',
        })
        this.updateMembers()
      }
    },

    // 登録に成功
    updateGroupMember() {
      this.$message({
        message: 'メンバー情報を保存しました。',
        type: 'success',
      })
      this.updateMembers()
    },
  },

  watch: {
    currentTab() {
      this.updateMembers()
    },
  },
}
</script>

<style lang="sass">
.admin-group
  .el-table__body
    tr.is-not-activated
      background: #f3f3f3
      span.status
        color: #bb0c0c
</style>

<style lang="sass" scoped>
.admin-group

  article
    margin-top: 1.5rem

    .members
      .note
        font-size: 0.85rem
        color: darken($color-1, 20%)
        margin: 0.5rem 0

      .header
        margin: 0.5rem 0
        h3
          font-size: 1.1rem
          display: inline-block
        .add
          margin-left: 1rem
        .download-link
          float: right
</style>
