// グループへのメンバの追加

import api from '../api'

const path = '/admin/user-groups/{id}/members'

export default (
  userGroupId,
  { number, organizationId, firstName, lastName, firstNameKana, lastNameKana, email }
) => {
  const url = path.replace('{id}', userGroupId)
  return api({
    method: 'post',
    url,
    params: {
      loginName: number,
      organizationId,
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      email,
    },
    auth: true,
  })
}
