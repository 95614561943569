// グループのメンバーの編集ダイアログ

<template lang="pug">
el-dialog.modify-group-member-dialog(
  title='メンバーの作成と編集',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)

  article

    el-form(
      :model='form',
      label-position='top',
      :rules='rules',
      ref='form'
    )

      el-form-item(
        label='受講者番号',
        prop='number'
      )
        el-input(v-model='form.number')

      //- 園名
      el-form-item.kindergarten(
        label='園名',
        required
      )
        el-row.row(:gutter='20')
          el-col(:span='12')
            el-select.select(
              placeholder='地区を選択',
              v-model='wardId'
            )
              el-option(
                v-for='item in wardList',
                :key='item.value',
                :label='item.name',
                :value='item.value'
              )
          el-col(:span='12')
            el-form-item(
              prop='organizationId'
            )
              el-select.select(
                placeholder='園名を選択',
                v-model='form.organizationId'
              )
                el-option(
                  v-for='item in organizationList',
                  :key='item.id',
                  :label='item.name'
                  :value='item.id'
                )

      //- 名前
      el-form-item(
        label='名前',
        required
      )
        el-row(:gutter='20')
          el-col(:span='12')
            el-form-item(
              prop='firstName'
            )
              el-input(
                placeholder='姓',
                v-model.trim='form.firstName'
              )
          el-col(:span='12')
            el-form-item(
              prop='lastName'
            )
              el-input(
                placeholder='名',
                v-model.trim='form.lastName'
              )

      el-form-item(
        label='なまえ(ひらがな)',
        required
      )
        el-row(:gutter='20')
          el-col(:span='12')
            el-form-item(
              prop='firstNameKana'
            )
              el-input(
                placeholder='せい',
                v-model.trim='form.firstNameKana'
              )
          el-col(:span='12')
            el-form-item(
              prop='lastNameKana'
            )
              el-input(
                placeholder='めい',
                v-model.trim='form.lastNameKana'
              )

      el-form-item(
        label='メールアドレス',
        prop='email'
      )
        el-input(
          placeholder='(例) abc@example.com',
          v-model.trim='form.email'
        )

      el-form-item(
        v-if='groupMemberId && isNotActivated'
        label='登録について'
      )
        el-checkbox(v-model='makeActivated') 登録済みにする

    footer
      el-button(
        type='primary'
        @click='save'
      ) 登録
      el-button(
        @click='close(false)'
      ) キャンセル

</template>

<script>
import uniqBy from 'lodash.uniqby'

// 園の情報の取得
import getOrganizationListApi from '@/api/admin/get-organization-list'
// メンバの新規登録
import createGroupMemberApi from '@/api/admin/create-group-member'
// ユーザの詳細情報の取得
import getUserApi from '@/api/admin/get-user'
// ユーザ情報の更新
import updateUserInfoApi from '@/api/admin/update-user-info'

export default {
  name: 'ModifyGroupMemberDialog',

  data() {
    return {
      form: {
        // = 受講者番号
        number: null,
        organizationId: null,
        firstName: null,
        lastName: null,
        firstNameKana: null,
        lastNameKana: null,
        email: null,
      },
      rules: {
        number: [
          { required: true, message: '必須項目です。' },
          { pattern: /^\d+$/, message: '数字で入力してください。' },
        ],
        organizationId: [{ required: true, message: '必須項目です。' }],
        firstName: [{ required: true, message: '必須項目です。' }],
        lastName: [{ required: true, message: '必須項目です。' }],
        firstNameKana: [
          { required: true, message: '必須項目です。' },
          { pattern: /^[ぁ-ゞー～]+$/, message: 'ひらがなで入力してください。' },
        ],
        lastNameKana: [
          { required: true, message: '必須項目です。' },
          { pattern: /^[ぁ-ゞー～]+$/, message: 'ひらがなで入力してください。' },
        ],
        email: [
          { required: true, message: '必須項目です。' },
          { type: 'email', message: 'メールアドレスを入力してください。' },
          { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~@-]+$/, message: '入力が正しくありません。' },
        ],
      },
      organizations: [],
      wardId: null,
      // 編集の場合で、まだ未アクティベートかどうか
      isNotActivated: true,
      // 更新時にアクティベートするかどうか
      makeActivated: false,
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userGroupId: {
      type: Number,
    },
    groupMemberId: {
      type: Number,
    },
  },

  computed: {
    wardList() {
      return uniqBy(
        this.organizations.map((item) => {
          return {
            name: item.regionName,
            value: item.regionId,
          }
        }),
        'value'
      )
    },
    // 地区を選択した場合は、その地区で絞り込まれる
    organizationList() {
      if (this.wardId) {
        return this.organizations.filter((item) => item.regionId === this.wardId)
      } else {
        return this.organizations
      }
    },
  },

  async created() {
    const response = await getOrganizationListApi()
    if (!response.ok) return
    this.organizations = response.payload.items
  },

  methods: {
    async open() {
      if (this.$refs.form) this.$refs.form.resetFields()
      this.wardId = null
      this.makeActivated = false
      if (this.groupMemberId) {
        const response = await getUserApi(this.groupMemberId)
        if (!response.ok) return
        const payload = response.payload
        this.form.number = payload.loginName
        this.form.organizationId = payload.organizationId
        this.form.firstName = payload.firstName
        this.form.lastName = payload.lastName
        this.form.firstNameKana = payload.firstNameKana
        this.form.lastNameKana = payload.lastNameKana
        this.form.email = payload.email
        // 未アクティベートか
        this.isNotActivated = !payload.activatedAt
      }
    },

    close(success) {
      if (success) this.$emit('ok')
      this.$emit('update:visible', false)
    },

    async save() {
      // バリデーション
      try {
        await this.$refs.form.validate()
      } catch (e) {
        await this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'warning',
        })
        return
      }
      let response
      // 更新かどうか
      if (this.groupMemberId) {
        response = await updateUserInfoApi(
          this.groupMemberId,
          Object.assign(
            {
              loginName: this.form.number,
              userGroupId: this.userGroupId,
              makeActivated: this.makeActivated,
            },
            this.form
          )
        )
      } else {
        response = await createGroupMemberApi(this.userGroupId, this.form)
      }
      if (!response.ok) {
        this.$alert('メンバーの保存に失敗しました。', 'エラー', {
          type: 'error',
        })
        return
      }
      this.close(true)
    },
  },
}
</script>

<style lang="sass" scoped>
.modify-group-member-dialog

  article
    margin-top: -20px

    .kindergarten
      .select
        width: 100%

    footer
      margin-top: 3rem
</style>
