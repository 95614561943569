// グループの削除

import api from '../api'

const path = '/admin/user-groups/{id}'

export default (userGroupId) => {
  const url = path.replace('{id}', userGroupId)
  return api({
    url,
    method: 'delete',
    auth: true,
  })
}
