// グループの作成

import api from '../api'

const path = '/admin/user-groups'

export default (name) => {
  return api({
    method: 'post',
    url: path,
    params: {
      name,
    },
    auth: true,
  })
}
