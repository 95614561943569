// ユーザ情報の更新

import api from '../api'

const path = '/admin/users/{id}'

export default (
  userId,
  {
    userGroupId,
    organizationId,
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    email,
    loginName,
    makeActivated = false,
  }
) => {
  const url = path.replace('{id}', userId)
  return api({
    method: 'put',
    url,
    params: {
      userGroupId,
      organizationId,
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      email,
      loginName,
      makeActivated,
    },
    auth: true,
  })
}
