// ユーザの削除

import api from '../api'

const path = '/admin/users/{id}'

export default (userId) => {
  const url = path.replace('{id}', userId)
  return api({
    url,
    method: 'delete',
    auth: true,
  })
}
